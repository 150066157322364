import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {PlatformBrowserComponent} from "../../shared/components/platform-browser.component";
import {PageTitleService} from "../../shared/services/page-title.service";
import {PageMetaService} from "../../shared/services/page-meta.service";
import {Router, RouterLink} from "@angular/router";
import {isPlatformBrowser, LocationStrategy} from "@angular/common";
import { Response }  from 'express';
import {RESPONSE} from "../../../express.token";
import {REQUEST} from "../../../express.token";
@Component({
    selector: 'app-not-found',
    imports: [
        RouterLink
    ],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss'
})

export class NotFoundComponentt extends PlatformBrowserComponent implements OnInit {

  constructor( @Inject(PLATFORM_ID)  platformId: object | any,
              @Optional() @Inject(RESPONSE) private response: Response,
              _pageTitleService: PageTitleService,
              _pageMetaService: PageMetaService,
               locStrat: LocationStrategy,
               router: Router,
  ) {
    super(_pageTitleService, _pageMetaService,platformId, router, locStrat);
  }

  override ngOnInit(): void {
    this.setTitle();
    this.setMetas();
    this.setNoIndexMeta();
    this.response.status(404);
    //console.log('AQUI')
  }


}

