<div class="wrapper">
  <div class="container home-container p-1" >
    <div class="row align-items-center">
      <div itemscope itemtype="https://schema.org/Service">
        <meta itemprop="serviceType" content="Phone Repair"/>
        <div itemprop="provider" itemscope itemtype="https://schema.org/Organization">
          <meta itemprop="name" content="Procell Repair Miami"/>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-12 order-md-1 pr-md-5 align-content-center">
        <h1 style="font-size: 2rem !important;" class="text-center mb-2 text-dark">
         NOT FOUND
        </h1>
        <div class="msg" ><span i18n>Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?</span>
          <a routerLink="/" title="Procell Repair Miami" ><p i18n>Let's go  home  and try from there.</p></a>
        </div>
      </div>
    </div>
  </div>
</div>
